import mixins from '@/mixins'

export default {
  name: 'works-header',
  mixin: [mixins],
  props: {
    item: {},
    workType: {},
    totalPoints: {},
    userPoints: {}
  },
  computed: {
    userDataLgSize () {
      if (this.item && this.item.finishedAt) {

        return 7;
      }

      return 4;
    },
    userDataLgOffset () {
      if (this.item && this.item.finishedAt) {

        return 5;
      }

      return 8;
    },
    userDataCollSize() {
      if (this.item && this.item.finishedAt) {

        return 3;
      }

      return 6;
    }
  }
}
