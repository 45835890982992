import { mapActions, mapGetters } from 'vuex'
import { snakeCase } from 'lodash'
import AudioRecorder from '@/components/audio-recorder/index.vue'
import AudioPlayer from '@/components/audio-player/index.vue'
import ImageFile from '@/components/image-file/index.vue'

export default {
  components: {
    AudioRecorder,
    AudioPlayer,
    ImageFile
  },
  props: {
    typeWork: {
      type: String,
      default: ''
    },
    workId: {
      type: Object,
      default: {}
    },
    userId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      comment: '',
      editCommentId: null,
      showRemoveDialog: false,
      idComment: null,
      isShowDialog: false,

      valid: false,
      fileSrc: '',
      fileName: '',
      isEmptyFileField: true,
      fileUrl: ''
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile/profile',
      messages: 'homeWorkComments/comments',
      list: 'files/list',
      isLoadingList: 'files/isLoadingList'
    }),
    author () {
      return this.profile.personalData.data.full_name
    },
    role () {
      return 'admin'
    },
  },
  created () {
    if (this.typeWork === 'homeWork') {
      this.getHomeWorkComments(this.workId.id)
    } else if (this.typeWork === 'graduateWork') {
      this.getGraduateWorkComments(this.workId.id)
    }
    this.fetchFiles({ typeId: 2 })
  },
  methods: {
    ...mapActions({
      getHomeWorkComments: 'homeWorkComments/GET_COMMENTS_HOME_WORK',
      getGraduateWorkComments: 'homeWorkComments/GET_COMMENTS_GRADUATE_WORK',
      sendHomeWorkComment: 'homeWorkComments/SEND_COMMENT_HOME_WORK',
      sendGraduateWorkComment: 'homeWorkComments/SEND_COMMENT_GRADUATE_WORK',
      updateHomeWorkComment: 'homeWorkComments/UPDATE_COMMENT_HOME_WORK',
      updateGraduateWorkComment: 'homeWorkComments/UPDATE_COMMENT_GRADUATE_WORK',
      deleteHomeWorkComment: 'homeWorkComments/DELETE_COMMENT_HOME_WORK',
      deleteGraduateWorkComment: 'homeWorkComments/DELETE_COMMENT_GRADUATE_WORK',
      clearCommentsList: 'homeWorkComments/CLEAR_COMMENTS_LIST',
      fetchFiles: 'files/GET_LAST_UPLOAD_FILES',
      upload: 'files/UPLOAD'
    }),
    checkAuthor(id) {
      return id !== this.userId
        ? "message client-message"
        : "message admin-message";
    },
    sendMessage(e) {
      e.preventDefault;
     
      if (this.comment && !this.editCommentId) {
				const objMessage = {
					comment: this.comment,
          type_id: 1
				}
        if (this.typeWork === 'homeWork') {
          this.sendHomeWorkComment({ workId: this.workId.id, comment: objMessage });
        } else if (this.typeWork === 'graduateWork') {
          this.sendGraduateWorkComment({ workId: this.workId.id, comment: objMessage });
        }
      } else if (this.editCommentId) {
        if (this.typeWork === 'homeWork') {
          this.updateHomeWorkComment({ workId: this.workId.id, comment: this.comment, id: this.editCommentId });
        } else if (this.typeWork === 'graduateWork') {
          this.updateGraduateWorkComment({ workId: this.workId.id, comment: this.comment, id: this.editCommentId });
        }
      }
      this.comment = ''
    },
    addFile () {
      this.isShowDialog = true
    },
    addAudio (file) {
      if (file) {
        this.fileUrl = file
        this.upload(this.createRequestPayload()).then(response => {
          this.$toasted.success(this.$t('success_upload'))
          const comment = {
            comment: response.src,
            type_id: 2
          }
          if (this.typeWork === 'homeWork') {
            this.sendHomeWorkComment({ workId: this.workId.id, comment: comment });
          } else if (this.typeWork === 'graduateWork') {
            this.sendGraduateWorkComment({ workId: this.workId.id, comment: comment });
          }
        })
      }
    },
    handleEditComment (comment) {
      this.comment = comment.comment
      this.editCommentId = comment.id
    },
    handleDeleteComment (id) {
      this.showRemoveDialog = true
      this.idComment = id
    },
    removeCommentItem () {
      if (this.typeWork === 'homeWork') {
        this.deleteHomeWorkComment({ workId: this.workId.id, id: this.idComment }).then(() => {
          this.getHomeWorkComments(this.workId.id)
          this.showRemoveDialog = false
        })
      } else if (this.typeWork === 'graduateWork') {
        this.deleteGraduateWorkComment({ workId: this.workId.id, id: this.idComment }).then(() => {
          this.getGraduateWorkComments(this.workId.id)
          this.showRemoveDialog = false
        })
      }
      this.removeComment(this.idComment)
    },
    changeFile (file) {
      if (file) {
        this.fileName = file.name
        this.fileSrc = URL.createObjectURL(file)
        this.isEmptyFileField = false
        this.fileUrl = file
      } else {
        this.clearFile()
      }
    },
    clearFile () {
      this.fileSrc = ''
      this.fileUrl = ''
      this.isEmptyFileField = true
    },
    createRequestPayload () {
      const formData = new FormData()
      const payload = {
        src: this.fileUrl,
        type_id: '2',
        entity: 'user_home_work',
        service: 'course'
      }
      for (const i in payload) {
        formData.append(snakeCase(i), payload[i])
      }
      return formData
    },
    uploadFile () {
      this.upload(this.createRequestPayload()).then(response => {
        this.fileUrl = response.src
        this.$toasted.success(this.$t('success_upload'))
      })
    },
    submitFile () {
      if (!this.fileUrl) this.fileUrl = this.fileSrc
      const comment = {
        comment: this.fileUrl,
        type_id: 2
      }
      if (this.typeWork === 'homeWork') {
        this.sendHomeWorkComment({ workId: this.workId.id, comment: comment });
      } else if (this.typeWork === 'graduateWork') {
        this.sendGraduateWorkComment({ workId: this.workId.id, comment: comment });
      }
      this.clearFile()
      this.isShowDialog = false
    },
    changeDialog (status) {
      if (!status) {
        this.fileSrc = ''
      }
      this.isShowDialog = status
    }
  },
  beforeDestroy () {
    this.clearCommentsList()
  }
}
