import AudioPlayer from '@/components/audio-player/index.vue'

export default {
  components: {
    AudioPlayer
  },
  data () {
    return {
      recorder: null,
      items: [],
      audioFile: null,
      openRecordVoice: false,
      isRecording: false,
      audio: new Audio(),
      currentTime: 0,
      trackDuration: 0,
      checkingCurrentPositionInTrack: ''
    }
  },
  filters: {
    timeFormat (s) {
      return (s - (s %= 60)) / 60 + (9 > s ? ':' : ':0') + s
    }
  },
  watch: {
    currentTime () {
      this.currentTime = Math.round(this.currentTime)
    },
    trackDuration () {
      this.trackDuration = Math.round(this.trackDuration)
    }
  },
  methods: {
    blobToFile (theBlob, fileName) {
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    },
    recordAudio () {
      var device = navigator.mediaDevices.getUserMedia({ audio: true })
      device.then((stream) => {
        this.recorder = new MediaRecorder(stream)
        this.recorder.ondataavailable = (e) => {
          this.items.push(e.data)
          if (this.recorder.state === 'inactive') {
            const blob = new Blob(this.items, { type: 'audio/mp3' })
            this.audioFile = blob
            this.audio.src = URL.createObjectURL(blob)
          }
        }
        this.recorder.start()
        this.isRecording = true
      })
    },
    pause () {
      this.isRecording = false
      clearTimeout(this.checkingCurrentPositionInTrack)
      if (this.recorder.state === 'recording') {
        this.recorder.pause()
      } else if (this.recorder.state === 'paused') {
        this.isRecording = true
        this.recorder.resume()
      }
    },
    stop () {
      this.recorder.stop()
      this.recorder.stream.getAudioTracks().forEach(function (track) { track.stop() })
      this.isRecording = false
      clearTimeout(this.checkingCurrentPositionInTrack)
    },
    play () {
      this.getCurrentTimeEverySecond(true)
      this.audio.play()
    },
    sendAudio () {
      this.$emit('uploadAudio', this.audioFile)
      this.openRecordVoice = false
      this.audioFile = null
      this.audio.src = null
      this.items = []
    },
    deleteAudio () {
      this.audioFile = null
      this.audio.src = null
      this.items = []
    },
    getCurrentTimeEverySecond () {
      const cotx = this
      this.checkingCurrentPositionInTrack = setTimeout(
        function () {
          cotx.currentTime = cotx.audio.currentTime
          cotx.trackDuration = cotx.audio.duration - cotx.currentTime
          cotx.getCurrentTimeEverySecond(true)
        }.bind(this),
        10
      )
    }
  },
  beforeDestroy () {
    clearTimeout(this.checkingCurrentPositionInTrack)
  }
}
