import { mapActions, mapGetters } from 'vuex'
import validationMixin from '@/mixins/validation'
import MainEditor from '@/components/editor/index.vue'
import AudioRecorder from '@/components/audio-recorder/index.vue'
import chatComponent from '@/components/chat-component/index.vue'

export default {
  name: 'home-work-footer',
  mixin: [validationMixin],
  props: {
    item: {},
    workType: {}
  },
  data () {
    return {
      valid: false,
      payload: {
        comment: '',
        points: '0',
        status: null
      }
    }
  },
  components: {
    MainEditor,
    AudioRecorder,
    chatComponent
  },
  created () {
    this.payload.comment = this.item.comment
    this.payload.points = this.item.points
    this.payload.status = this.item.status.data.id
  },
  computed: {
    ...mapGetters({
      isLoading: 'homeWorkResult/isLoading',
      setting: 'courses/setting',
    })
  },
  methods: {
    ...mapActions({
      cancel: 'homeWorkResult/CANCEL',
      complete: 'homeWorkResult/COMPLETE_WORK'
    }),
    cancelHomeWork () {
      const formData = new FormData()
      formData.append('workType', this.workType)
      formData.append('currentId', this.item.id)
      formData.append('_method', 'patch')

      this.cancel(formData).then(() => {
        this.$toasted.success(this.$t('success_cancel'))
      })
    },
    completeHomeWork () {
      this.$refs.form.validate()
      if (this.valid) {
        const formData = new FormData()
        formData.append('workType', this.workType)
        formData.append('currentId', this.item.id)
        formData.append('comment', this.payload.comment)
        formData.append('points', this.payload.points)
        formData.append('status_id', this.payload.status)
        formData.append('_method', 'patch')

        this.complete(formData).then(() => {
          this.$toasted.success(this.$t('success_updated'))
        })
      }
    },
    uploadAudio (audio) {
      const a = document.getElementById('linkAudio')
      a.download = 'record.ogg'
      a.href = audio
    },
    changeStatusWork (e) {
      if (this.payload.points === '' || this.payload.points === 0) {
        if (e !== 6 && e !== undefined) {
          this.valid = true
        } else {
          this.valid = false
        }
      }
    }
  }
}
