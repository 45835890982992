import { mapActions, mapGetters, mapMutations } from 'vuex'
import gwHeader from '../../components/header/index.vue'
import gwFooter from '../../components/footer/index.vue'
import gwTemplate from '../../components/answer-graduate-work/index.vue'

export default {
  name: 'user-graduate-work-details',
  components: {
    gwHeader,
    gwFooter,
    gwTemplate
  },
  created() {
    if (this.$route.params.hasOwnProperty('userGraduateWorkId')) {
      this.fetchItem({
        workType: 'graduateWork',
        graduateWorkId: this.$route.params.userGraduateWorkId
      })
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'homeWorkResult/currentItem'
    })
  },
  methods: {
    ...mapActions({
      fetchItem: 'homeWorkResult/GET_LIST_ITEM'
    }),
    ...mapMutations({
      setItem: 'homeWorkResult/SET_ITEM'
    })
  },
  destroyed() {
    this.setItem(null)
  }
}
