<template>
  <div class="player">
    <button
      class="player-button"
      @click="playAudio()"
    >
      <v-icon small color="white" class="player-button-icon">mdi-play</v-icon>
    </button>

    <div class="player-progress">
      <div class="player-progress-bar">
        <div
          class="player-progress-bar-line"
          :style="{ width: currentProgressBar + '%' }"
        ></div>
      </div>
      <div class="player-progress-time" style="text-align: center">
        <!-- <span class="player-progress-time-current">{{
          currentTime | timeFormat
        }}</span>
        <span class="player-progress-time-total">
          - {{ trackDuration | timeFormat }}</span
        > -->
      </div>
    </div>
  </div>
</template>

<script>
// import IconPlay from "~/assets/images/icons/play.svg?inline";
// import IconPause from "~/assets/images/icons/pause.svg?inline";
export default {
//   components: {
//     IconPlay,
//     IconPause,
//   },
  props: {
    audioFile: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentlyPlaying: false,
      currentlyStopped: false,
      currentTime: 0,
      trackDuration: 0,
      currentProgressBar: 0,
      checkingCurrentPositionInTrack: '',
      audio: ''
    }
  },
  filters: {
    timeFormat (s) {
      return (s - (s %= 60)) / 60 + (9 > s ? ':' : ':0') + s
    }
  },
  mounted () {
    this.changeSong()
    this.audio.loop = false
  },
  methods: {
    changeSong () {
      const wasPlaying = this.currentlyPlaying
      this.audio = new Audio(this.audioFile)
      const contx = this
      this.audio.addEventListener('loadedmetadata', function () {
        if (this.duration !== Infinity) {
          contx.trackDuration = Math.round(this.duration)
        }
      })
      this.audio.addEventListener('ended', this.handleEnded)
      if (wasPlaying) {
        this.playAudio()
      }
    },
    playAudio () {
      if (!this.currentlyPlaying) {
        this.getCurrentTimeEverySecond(true)
        this.currentlyPlaying = true
        this.audio.play()
      } else {
        this.stopAudio()
      }
      this.currentlyStopped = false
    },
    stopAudio () {
      this.audio.pause()
      this.currentlyPlaying = false
      this.pausedMusic()
    },
    handleEnded () {
      this.stopAudio()
    },
    getCurrentTimeEverySecond: function (startStop) {
      const cotx = this
      this.checkingCurrentPositionInTrack = setTimeout(
        function () {
          cotx.currentTime = cotx.audio.currentTime
          cotx.trackDuration = cotx.audio.duration - cotx.currentTime

          cotx.currentProgressBar = Math.round(
            (cotx.currentTime / cotx.audio.duration) * 100
          )
          cotx.getCurrentTimeEverySecond(true)
        }.bind(this),
        10
      )
    },
    pausedMusic () {
      clearTimeout(this.checkingCurrentPositionInTrack)
    }
  },
  watch: {
    currentTime () {
      this.currentTime = Math.round(this.currentTime)
    },
    trackDuration () {
      this.trackDuration = Math.round(this.trackDuration)
    },
    audioFile () {
      this.$nextTick((vm) => {
        this.changeSong()
      })
    }
  },
  beforeDestroy: function () {
    this.audio.removeEventListener('ended', this.handleEnded)
    this.audio.removeEventListener('loadedmetadata', this.handleEnded)
    clearTimeout(this.checkingCurrentPositionInTrack)
  }
}
</script>

<style lang="scss" scoped>
.player {
  display: flex;
  align-items: center;
  width: 250px;
  &-button {
    position: relative;
    background-color: #111;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    &-icon {
      color: #fff;
    }
  }
  &-progress {
    width: 85%;
    margin-left: 10px;
    &-bar {
      background-color: #d3d8df;
      &-line {
        background-color: #111;
        width: 0px;
        height: 3px;
        transition: 100ms;
      }
    }
    &-time {
      display: flex;
      justify-content: space-between;
      padding-top: 5px;
      &-current,
      &-total {
        font-weight: normal;
        font-size: 12px;
        color: #6a6a6a;
      }
    }
  }
}
</style>
